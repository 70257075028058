/**
 * The `guest` middleware should be applied to routes that should
 * only be accessible to unauthenticated users (e.g. login, register)
 *
 * If a user is already logged in, they will be redirected to the dashboard instead
 */
export default defineNuxtRouteMiddleware(() => {
  const user = useSupabaseUser()
  if (user.value) {
    return navigateTo('/dashboard')
  }
})
